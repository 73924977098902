<template>
  <div class="page">
    <div class="nav">
      <HeaderBase :sear="'hide'"/>
    </div>

    <div class="paymoney">
      <div class="head">
        <h3 class="head_time">{{$t('m.buy_settleaccounts.Please')}}
           <span>
            <count-down :Time="datalist.timer"></count-down>
          </span> {{$t('m.buy_settleaccounts.Please_complete')}}</h3>
        <span class="head_paym">{{$t('m.buy_settleaccounts.Amount_due')}}：<i class="head_number">{{datalist.payAmount}}</i></span>
      </div>
      <div class="body">
        <div class="paytitle">
          {{$t('m.buy_settleaccounts.Payment_Methods')}}
        </div>
        <div class="pay_body">
          <ul class="pay_qu">
            <li class="balancepay"
            :class="isactive=='balan'?'isact':''"
            @click="isactFun('balan')">
              {{$t('m.buy_settleaccounts.Balance_payment')}}
            </li>
            <!-- <li class="unionpay"
            :class="isactive=='union'?'isact':''"
            @click="isactFun('balan')">
              <img src="../../static/imges/unionpay.png" alt="">
            </li> -->
            <li class="wechatpay"
            :class="isactive=='wechat'?'isact':''"
            @click="isactFun('balan')">
              <img src="../../static/imges/wechat.jpg" alt="">
            </li>
          </ul>
          <!-- <ul class="pay_bank" v-if="isactive=='wechat'?false:true">
            <li v-for="i in bankCard" :key="i"></li>
            <span class="add_bank_card">添加银行卡+</span>
          </ul> -->
        </div>
        <div class="wechat_pay_div" v-if="isactive!=='wechat'?false:true">
          <div class="wechat_pay_div_left">
            <p>请使用微信扫码支付</p>
            <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/PhoneApp.png" alt="">
            <span>距离二维码过期还剩<i>40</i>秒</span>
            <span>过期后请刷新页面重新获取二维码</span>
          </div>
          <img src="../../static/imges/wechat_msg.png" alt="">
        </div>
      </div>
      <div class="pay_footer" v-if="isactive=='wechat'?false:true">
        <div class="paytitle">
          {{$t('m.buy_settleaccounts.Payment_password')}}
        </div>
        <div class="pay_input">
          <el-input
            placeholder=" "
            v-model="datalist.passwords"
            type="password"
            maxlength="6"
            minlength="6">
            </el-input>
            <!-- <span class="bank_psw">忘记密码</span> -->
        </div>
        <span class="tishi">{{$t('m.buy_settleaccounts.Please_enter_a_6_digit_payment_PIN')}}</span>

        <el-button class="pay_go" @click="payAmountfun"
          :loading="loadingbtn">{{$t('m.buy_settleaccounts.pay')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
import { toRaw } from 'vue'
import CountDown from '../../components/CountDown.vue'
import HeaderBase from '../../components/HeaderBase.vue'
import moment from 'moment'
import { ElMessage } from 'element-plus'
import http from '../../api/http.js'
import { setStore, getStore, removeStore } from '../../tools/storage'

export default {
  components: { CountDown, HeaderBase },
  name: 'settleAccounts',
  data () {
    return {
      isactive: 'balan',
      bankCard: [],
      datalist: {
        passwords: ref(''),
        orderSn: ref(''),
        payAmount: '',
        timer: {
          eventEndTime: getStore('settleAccTime'), // 获取存储的时间 订单倒计时
          eventStartTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
      },
      capitalAccount: '',
      loadingbtn: false
    }
  },
  methods: {
    isactFun (k) {
      this.isactive = k
    },
    payAmountfun () {
      const loading = this.$loading({
        lock: true,
        text: '支付中'
      })
      // console.log(this.datalist)
      if (/^\d{6}$/.test(this.datalist.passwords)) {
        // const postData = {
        //   orderSns: toRaw(this.datalist.orderSn),
        //   password: toRaw(this.datalist.passwords)
        // }
        const passwords = this.datalist.passwords
        // console.log(passwords)
        const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
        if (!capitalAccount) {
          ElMessage.warning({
            message: '请查看资金账户信息是否通过'
          })
          loading.close()
          this.$router.push('/personalcenter/myorder')
          return
        }
        // console.log(capitalAccount)
        const md5password = this.$md5(`${capitalAccount}${passwords}`)
        const postData = {
          orderSns: toRaw(this.datalist.orderSn),
          password: md5password
        }
        // console.log(postData)
        http.post('/mall-order/order/datePresaleOrderPay', postData, false)
          .then(res => {
            console.log(res)
            if (res.data.flag) {
              ElMessage.success({
                message: res.data.msg
              })
              this.datalist.passwords = ''
              loading.close()
              setStore('payMoneyNumber', this.datalist.payAmount)
              removeStore('settleAccTime')
              this.$router.push('/paySuccess') // 成功到支付成功页面
            } else {
              // this.$router.push('/personalcenter/myorder') // 失败到我的订单页
              ElMessage.error({
                message: res.data.msg
              })
              loading.close()
            }
          }).catch(err => {
            console.log(err)
          })
        this.loadingbtn = false
      } else {
        loading.close()
        ElMessage.error({ message: '请输入6位支付密码' })
        this.loadingbtn = false
      }
    }
  },
  props: ['payAmount', 'orderSn'],
  created () {
    const usermessage = getStore('usermessage')
    this.capitalAccount = JSON.parse(usermessage)
    // console.log(getStore('usermessage'))
    this.datalist.payAmount = JSON.parse(decodeURIComponent(this.$route.query.payAmount))
    this.datalist.orderSn = decodeURIComponent(this.$route.query.orderSn)
  },
  mounted () {
    // this.datalist.orderSn = this.$route.params.orderSn
    // this.datalist.payAmount = this.$route.params.payAmount
    // console.log(this.$route.params.payAmount)
    // console.log(this.$route.params.orderSn)
    // console.log(JSON.parse(decodeURIComponent(this.$route.params.payAmount)))
    // console.log(decodeURIComponent(this.$route.params.orderSn))
  },
  setup () {
    // console.log(route.params)
    return {

    }
  }
}
</script>

<style  lang="scss" scoped>
@import './buy.scss';

// @import '../../style/viriables.scss';
// .page{
//   width: 100%;
//   // height: 400px;
//   background: $allbgColor;
//   height: 100%;
//   padding-bottom: 60px;
//   .nav{
//     // height: 30px;
//     width: 100%;
//     background: $divbgColor;
//     ul{
//       display: block;
//       line-height: 30px;
//       width: 1200px;
//       margin: 0 auto;
//       height: 30px;
//       li{
//         float: left;
//         display: flex;
//         height: 30px;
//         margin-right: 20px;
//         cursor: pointer;
//       }
//     }
//     #search{
//       width: 100%;
//       height: 120px;
//       background: #ffffff;
//       .logosearch{
//         display: flex;
//         width: 1200px;
//         margin: 0 auto;
//         height: 100%;
//         z-index: 99;
//         justify-content: left;
//         align-items: center;
//         #logo{
//           img{
//             margin-right: 80px;
//             z-index: 9999;
//           }
//         }
//       }
//     }
//   }
//   .paymoney{
//     margin: 0 auto;
//     font-size: 14px;
//     font-family: Microsoft YaHei;
//     font-weight: 400;
//     width: 1200px;
//     padding: 30px 100px;
//     margin-top: 10px;
//     min-height: 860px;
//     background: #ffffff;
//     display: flex;
//     flex-flow: column;
//     .head{
//       padding: 50px 0;
//       display: flex;
//       justify-content: space-between;
//       .head_time{
//         color: #FF0000;
//       }
//       .head_paym{
//         color: #999999;
//         .head_number{
//           color: #C6824E;
//           font-size: 24px;
//         }
//       }
//     }
//     .body{
//       border-bottom:1px solid #EAEAEA ;
//       border-top:1px solid #EAEAEA ;
//       padding: 40px 0;
//       .paytitle{
//         // margin: 30px 0;
//         margin-bottom: 30px;
//         color: #333333;
//         font-size: 16px;
//       }
//       .pay_body{
//         width: 473px;
//         display: flex;
//         flex-flow: column;
//         .pay_qu{
//           // width: 500px;
//           display: flex;
//           justify-content: space-between;
//           height: 60px;
//           margin-bottom: 20px;
//           .balancepay{
//             text-align: center;
//             font-size: 18px;
//             line-height: 60px;
//           }
//           li{
//             // float: left;
//             width: 226px;
//             height: 60px;
//             z-index: 2;
//             transition: all 0.2s;
//             border: 2px solid #EAEAEA;
//             img{
//               display: block;
//               height: 60px;
//               margin: 0 auto;
//             }
//           }
//           .unionpay{
//             position: relative;
//             img{
//               z-index: 1;
//             }
//           }
//           .isact{
//             border-color: #C6824E;
//           }
//         }
//         .pay_bank{
//           li{
//             height: 60px;
//             background: #FFFFFF;
//             border: 1px solid #EAEAEA;
//             margin-bottom: 20px;
//           }
//           .add_bank_card{
//             display: block;
//             text-align: center;
//             line-height: 60px;
//             width: 227px;
//             height: 60px;
//             background: #FFFFFF;
//             border: 1px solid #EAEAEA;
//             cursor: pointer;

//           }
//         }
//       }
//       .wechat_pay_div{
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         .wechat_pay_div_left{
//           display: flex;
//           flex-flow: column;
//           p{
//             text-align: center;
//             font-size: 18px;
//             color: #333333;
//           }
//           img{
//             width: 200px;
//             margin: 20px 0;
//             height: 200px;
//           }
//           span{
//             font-size: 14px;
//             color: #C6824E;
//             line-height: 25px;
//           }
//         }
//       }
//     }
//     .pay_footer{
//       .paytitle{
//         margin: 40px 0 30px;
//         color: #333333;
//         font-size: 16px;
//       }
//       .pay_input{
//         display: flex;
//         align-items: center;
//         width: 280px;
//         .el-input{
//           width: 200px;
//           margin-right: 20px;
//         }
//         .bank_psw{
//           color: #C6824E;
//         }
//       }
//       .tishi{
//         display: block;
//         margin: 10px 0 40px;
//         color: #999999;
//       }
//       .pay_go{
//         width: 160px;
//         height: 50px;
//         background: linear-gradient(0deg, #280E01 0%, #673000 100%);
//         font-size: 18px;
//         color: #FEFEFE;
//       }
//     }
//   }
// }
</style>
