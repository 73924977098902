<template>
  <span>{{this.day}}{{$t('m.auction_englishauction.day')}}{{this.hr}}{{$t('m.auction_englishauction.hour')}}{{this.min}}{{$t('m.auction_englishauction.minute')}}{{this.sec}}{{$t('m.auction_englishauction.second')}}</span>
</template>

<script>
import { ref } from 'vue'
import emitter from '../tools/bus'
// import { onMounted } from 'vue-demi'
/*
*eventStartTime： 开始时间
*eventEndTime：结束时间
*time： 服务器返回的当前时间
*/
export default {
  name: 'CountDown',
  data () {
    return {
      eventEndTime: ref(''),
      eventStartTime: ref(''),
      msecTime: ref(0),
      day: ref(''),
      hr: ref(''),
      min: ref(''),
      sec: ref('')
    }
  },
  methods: {
    countdown () {
      const end = Date.parse(new Date(this.eventEndTime))
      const now = Date.parse(new Date())
      const msec = end - now

      // console.log(msec)
      if (msec < 0) return

      const day = parseInt(msec / 1000 / 60 / 60 / 24)
      const hr = parseInt(msec / 1000 / 60 / 60 % 24)
      const min = parseInt(msec / 1000 / 60 % 60)
      const sec = parseInt(msec / 1000 % 60)
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      const that = this
      if (min >= 0 && sec >= 0) {
        // 倒计时结束关闭订单
        if (min === 0 && sec === 0) {
          return
        }
        setTimeout(function () {
          that.countdown()
        }, 1000)
      }
    },
    countdown2 (time) {
      this.msecTime = Date.parse(new Date(time)) - new Date().getTime()
      // this.msecTime -= 1000
      // console.log(this.msecTime)
      if (this.msecTime < 0) return

      const day = parseInt(this.msecTime / 1000 / 60 / 60 / 24)
      const hr = parseInt(this.msecTime / 1000 / 60 / 60 % 24)
      const min = parseInt(this.msecTime / 1000 / 60 % 60)
      const sec = parseInt(this.msecTime / 1000 % 60)
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      const that = this
      // console.log(this.day + '-' + this.hr + '-' + this.min + '-' + this.sec)
      // this.$forceUpdate()
      if (min >= 0 && sec >= 0) {
        // 倒计时结束退出
        if (min === 0 && sec === 0 && hr === 0) {
          // emitter.off()
          return
        }
        setTimeout(function () {
          that.countdown2(time)
        }, 1000)
      }
    },
    startTime (data) { // 父组件传入的时间
      // console.log(this.$props.Time)
      if (this.$props.Time === undefined) {
        return ''
      } else {
        console.log(this.$props.Time)
        this.eventEndTime = this.$props.Time.eventEndTime
        this.eventStartTime = this.$props.Time.eventStartTime
        // this.msecTime = Date.parse(new Date(this.eventEndTime)) - Date.parse(new Date(this.eventStartTime))
        this.countdown2(this.eventEndTime)
      }
    }
  },
  props: ['Time'],
  mounted () {
    // this.countdown()
    this.startTime()
    emitter.on('englishAuctionTime', (data) => { // 收到的时间
      this.eventEndTime = data.data.eventEndTime // 结束时间
      this.eventStartTime = data.data.eventStartTime // 开始时间
      console.log(data)
      if (data.status === 1) {
        this.countdown2(this.eventEndTime)
      } else {
        // this.msecTime = Date.parse(new Date(this.eventStartTime)) - Date.parse(new Date())
        this.countdown2(this.eventStartTime)
      }
      // console.log(this.msecTime)
      // this.countdown2()
    })
  },
  setup (props, ctx) {
    // console.log(props.Time)
    return {

    }
  }
}
</script>

<style  lang="scss" scoped>

</style>
